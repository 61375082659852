


























































import { Vue, Component, Prop } from "vue-property-decorator";
import { MobileConfirmedOrderProperties } from "./MobileConfirmedOrderAView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import CustomerOrderDetailService from "@/services/CustomerOrderDetailService";
import ExceptionUtil from "@/utilities/ExceptionUtil";

@Component({
    data: () => ({
        recordCounts: [10, 20, 30, 40, 50],
        pageNo: 1,
        pageCount: 1,
        recordCount: 20,
        loading: false,
        customerOrderDetails: [],
    }),
    computed: {
        screenSmall: function() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        screenMedium: function() {
            return this.$vuetify.breakpoint.mdAndDown;
        }
    }
})
export default class MobileConfirmedOrderDetailView extends Vue {
    @Prop() private properties: MobileConfirmedOrderProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private customerOrder: any;
    private customerOrderDetailService = new CustomerOrderDetailService();

    public formatPrice(v: any) {
        const symbol = this.properties.currency.symbol ?? "";
        return symbol + " " + v.toFixed(2);
    }

    public formatTotalAmount(v: any) {
        const symbol = this.properties.currency.symbol ?? "";
        return symbol + " " + v.toFixed(2);
    }

	public formatComment(detail: any) {
		var m = detail.comment.message;
		const r = detail.remarks ?? "";

		if (r !== "") {
			const t = this.$t('text.remarks');
			m += `\n${t}: ${detail.remarks}`;
		}
		return m;
	}

    public created() {
        this.load();
    }

    public async load() {
        if (!this.customerOrder) {
            return;
        }

        const pageNo = this.$data.pageNo;
        const rowCount = this.$data.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.loading = true;
        try {
            const r = await this.customerOrderDetailService.current({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                customerOrderId: this.customerOrder.id,
            });

            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;
            const customerOrderDetails = r.data.customerOrderDetails;
            this.$data.customerOrderDetails = customerOrderDetails.sort((lhs, rhs) => lhs.id - rhs.id);
            await Vue.nextTick();
            this.$data.pageCount = count;
            if (pageNo > count) {
                this.$data.pageNo = count;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loading = false;
        }
    }
}
